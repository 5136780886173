<template lang="pug">
	div(class="custom")
		div(class="row")
			div(class="col s12")
				simplebox(content="Du benötigst Funktionen die dir unser DIY Ticketing nicht bietet? Kein Problem! Unsere Plattform ist modular aufgebaut und wir können dir ein maßgeschneidertes System für deine Anforderungen bereitstellen.<br/><br/>Lass uns einfach über deine Anforderungen sprechen und sehen, was wir tun können!")

		div(class="row")
			div(class="col s12")
				separator(text="So funktionierts")

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--26.png" headline="Grundfunktionen & Pricing" teaser="Unsere Custom-Ticketing-Lösung enthält alle Standard Funktionen unserer DIY-Ticketing-Lösung und wird mit 0,60€/Ticket abgerechnet." link_label="DIY Features & Pricing" link_target="/diy-ticketing" v-bind:link_open_external="false" button_color="#233642")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--27.png" headline="Zusatzfunktionen" teaser="Individuelle Zusatzfunktionen auf Bestellung" link_label="Zum Ablauf" link_target="#process-description" v-bind:link_internal_scroll="true" v-bind:link_open_external="false" button_color="#e1783e")

		div(class="row")
			div(class="col s12")
				separator(text="Ablauf" id="process-description")

		div(class="row flex box-row")
			div(class="col l3 m12 s12")
				tile(icon="icons/Iconset_DGBRT--28.png" headline="Kontakt Formular" v-bind:small_headline="true" teaser="Ausfüllen des Kontaktformulares")
			div(class="col l3 m12 s12")
				tile(icon="icons/Iconset_DGBRT--29.png" headline="Erst Besrpechung" v-bind:small_headline="true" teaser="Erstbesprechung mit kostenloser und unverbindlicher Projektschätzung" link_label="Termin" link_target="#sign-up-now" v-bind:link_internal_scroll="true")
			div(class="col l3 m12 s12")
				tile(icon="icons/Iconset_DGBRT--30.png" headline="Angebot" v-bind:small_headline="true" teaser="Wir erstellen dir ein Angebot zur Umsetzung des Projekts.")
			div(class="col l3 m12 s12")
				tile(icon="icons/Iconset_DGBRT--31.png" headline="Produktion" v-bind:small_headline="true" teaser="Wir setzen das Projekt um")

		div(class="row")
			div(class="col s12")
				separator(text="Abrechnung")

		div(class="row flex box-row")
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--32.png" headline="Produktionskosten")
				div(class="transparentbox")
					ul(class="bullets")
						li Basierend auf unserem Erstgespräch erstellen wir dir ein unverbindliches Angebot für die Umsetzung deiner persönlichen Ticketing-Anforderungen
						li Diese Produktionskosten werden bei Vertragsschluss fällig und sofort abgerechnet
			div(class="col l6 m12 s12")
				tile(icon="icons/Iconset_DGBRT--33.png" headline="Guthaben")
				div(class="transparentbox")
					ul(class="bullets")
						li Bis zu 100% der Produktionskosten werden dir auf dein Dagobert Kundenkonto gutgeschrieben
						li Kosten die mit dem Ticketverkauf entstehen werden zunächst mit deinem Kundenkonto verrechnet
						li Bis das Guthaben auf deinem Kundenkonto aufgebraucht ist, entstehen dir also keine weiteren Kosten durch die Nutzung des Dagobert Systems  

		div(class="row")
			div(class="col s12")
				div(class="custom-simplebox")
					div(class="row row-no-mb")
						div(class="col s12 valign-wrapper")
							h4 Beispiel
					div(class="row")
						div(class="col l4 m12 s12 valign-wrapper")
							div
								b Produktionskosten:
								h4 10.000€
								| <i>Wird bei Beginn der Produktion in Rechnung gestellt</i>
						div(class="col l4 m12 s12 valign-wrapper")
							div
								| Ergeben ein <b>Guthaben</b> in Höhe von bis zu:
								h4 10.000€
						div(class="col l4 m12 s12 valign-wrapper")
							div
								b Kosten:
								h4 0,60€
								| /verkauftem Ticket
					div(class="row row-no-mb")
						div(class="col s12 valign-wrapper")
							div
								h4 Bedeutet:
								| In den <b>ersten 12 Monaten</b> der Vertragslaufzet können bis zu <b>16.667 Tickets</b> verkauft werden, <b>ohne dass weitere Kosten anfallen</b>

		div(class="row")
			div(class="col s12")
				separator(text="Sprich uns an!" id="sign-up-now")

		div(class="row")
			div(class="col s12")
				form-block(teaser="Hast du spezielle Anforderungen an deinen Ticketverkauf? Melde dich einfach! Wir sind schon gespannt, was wir für dich tun können!" source="Custom Ticketing Seite" v-bind:form_config="formConfig" submit_label="Abschicken" endpoint="https://website-backend.dagorbert-apps.de/mails/relay" form_submitted_message="Vielen Dank!<br/>Wir melden uns so schnell wie möglich!")

</template>

<style scoped>
.transparentbox {
	background-color: rgba(255, 255, 255, 0.7);
	color: #233642;
	padding: 2em;
	font-size: 1.2em;
	width: 100%;
}

.custom-simplebox {
	background-color: rgba(255, 255, 255, 0.7);
	padding: 3em 4em;
	color: #233642;
	font-size: 1.2em;
}

@media screen and (max-width: 992px) {
	#page .transparentbox {
		margin-top: -25px;
	}
}
</style>

<script>
import UrlMixin from '@/mixins/UrlMixin';

import Simplebox from '@/components/Simplebox';
import Separator from '@/components/Separator';
import Tile from '@/components/Tile';
import Form from '@/components/Form';

export default {
	mixins: [ UrlMixin ],
	components: {
		'simplebox': Simplebox,
		'separator': Separator,
		'tile': Tile,
		'form-block': Form
	},
	data: function() {
		return {
			'formConfig': [
				{
					'label': 'Kurze Beschreibung der gewünschten Funktionen',
					'key': 'description',
					'type': 'textarea',
					'required': true
				},
				{
					'label': 'Unternehmen',
					'key': 'company',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Name',
					'key': 'contact_name',
					'type': 'text',
					'required': true
				},
				{
					'label': 'E-Mail Adresse',
					'key': 'contact_email',
					'type': 'email',
					'required': true
				},
				{
					'label': 'Telefonnummer',
					'key': 'phone',
					'type': 'text',
					'required': true
				},
				{
					'label': 'Erwartetes Ticketvolumen pro Jahr',
					'key': 'volume',
					'type': 'number',
					'required': false
				}
			]
		}
	}
}
</script>